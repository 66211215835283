/**
 * 系统管理-菜单管理
 * luxinwen
 * 2022-08
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content">
        <Button type="info" @click="expand(true)">全部展开</Button>
        <Button type="info" @click="expand(false)">全部收起</Button>
      </div>
      <div class="page-main-content">
        <Tree class="tree-editor" :data="treeData" :render="renderContent" :select-node="false" @on-toggle-expand="toggleExpand"></Tree>
      </div>
    </div>

    <edit-drawer v-model="drawerDisplay" :data="drawerData" :flag="drawerFlag" @success="getData"></edit-drawer>
  </div>
</template>

<script>
  import editDrawer from './edit.vue';
  import { MERCHANTS } from '@/util/enum';

  export default {
    components: {
      editDrawer
    },
    data() {
      return {
        treeData: [
          {
            title: MERCHANTS.OSP,
            row: {
              merchantCode: 'OSP'
            },
            expand: true,
            render: (h, { root, node, data }) => {
              return this.renderRoot(h, { root, node, data });
            },
            children: []
          }
        ],
        drawerDisplay: false, // 是否显示编辑抽屉
        drawerData: {},       // 编辑抽屉数据
        drawerFlag: 'add',    // 编辑抽屉状态
        treeExpand: {},
        isExpand: false
      };
    },
    created() {
      this.getData();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        this.$axios({
          url: this.$api.system.queryMenus,
          data: {},
          loading: true
        }).then(data => {
          let list = data || [];
          let menuRoot = [];  // 一级菜单数组
          let menuSub = []; // 子菜单数组（二级菜单和三级菜单）
          // 遍历接口返回数据，区分出一级菜单和子菜单
          list.forEach(item => {
            let parent;
            let level;
            if (item.parentMenuId === 0) {
              parent = menuRoot;
              level = 1;
            } else {
              parent = menuSub;
              level = 2;
            }
            parent.push({
              title: `${item.menuName} [${item.menuCode}]`,
              level,
              row: item,
              expand: this.treeExpand.hasOwnProperty(item.menuId) ? this.treeExpand[item.menuId] : this.isExpand,
              children: []
            });
          });
          // 先处理子菜单，倒序排列，然后找出三级菜单（按钮级别）并放入对应的二级菜单中
          menuSub.reverse().forEach(item => {
            let index = menuSub.findIndex(parent => parent.row.menuId === item.row.parentMenuId);
            if (index > -1) {
              item.level = 3;
              menuSub[index].children.push(item);
            }
          });
          // 再把二级菜单放入对应的一级菜单中
          menuSub.forEach(item => {
            let index = menuRoot.findIndex(parent => parent.row.menuId === item.row.parentMenuId);
            if (index > -1) {
              menuRoot[index].children.push(item);
            }
          });
          // 最后清空菜单树数据，再把最新的数据填充入对应的菜单树
          this.treeData[0].children = [];
          menuRoot.reverse().forEach(item => {
            if (item.row.merchantCode === 'OSP') {
              this.treeData[0].children.push(item);
            }
          });
        });
      },
      /**
       * 树形渲染函数（一级菜单）
       */
      renderRoot(h, { root, node, data }) {
        return h('span', {
          style: {
            display: 'inline-block',
            width: '100%'
          }
        }, [
          h('span', [
            h('Icon', {
              props: {
                type: 'ios-folder-outline'
              },
              style: {
                marginRight: '8px'
              }
            }),
            h('span', data.title)
          ]),
          h('span', {
            style: {
              display: 'inline-block',
              float: 'right',
              marginRight: '32px'
            }
          }, [
            h('Button', {
              props: {
                type: 'primary',
                size: 'small'
              },
              on: {
                click: () => {
                  this.addData(root, node, data);
                }
              },
              directives: [
                {
                  name: 'grant',
                  value: 'OSP_SYS_MENU_SAVE_ROOT'
                }
              ]
            }, '添加一级菜单')
          ])
        ]);
      },
      /**
       * 树形渲染函数（子菜单）
       */
      renderContent(h, { root, node, data }) {
        return h('span', {
          style: {
            display: 'inline-block',
            width: '100%'
          }
        }, [
          h('span', [
            h('Icon', {
              props: {
                type: data.children.length > 0 ? 'ios-folder-outline' : 'ios-paper-outline'
              },
              style: {
                marginRight: '8px'
              }
            }),
            h('span', data.title)
          ]),
          h('span', {
            style: {
              display: 'inline-block',
              float: 'right',
              marginRight: '32px'
            }
          }, [
            h('Button', {
              props: {
                type: data.level >= 2 ? 'default' : 'info',
                size: 'small'
              },
              style: {
                display: data.level >= 3 ? 'none' : ''
              },
              on: {
                click: () => {
                  this.addData(root, node, data);
                }
              },
              directives: [
                {
                  name: 'grant',
                  value: data.level >= 2 ? 'OSP_SYS_MENU_SAVE_BUTTON' : 'OSP_SYS_MENU_SAVE_SUB'
                }
              ]
            }, `添加${data.level >= 2 ? '按钮权限' : '二级菜单'}`),
            h('Button', {
              props: {
                size: 'small'
              },
              on: {
                click: () => {
                  this.editData(root, node, data, true);
                }
              },
              directives: [
                {
                  name: 'grant',
                  value: 'OSP_SYS_MENU_VIEW'
                }
              ]
            }, '查看'),
            h('Button', {
              props: {
                size: 'small'
              },
              on: {
                click: () => {
                  this.editData(root, node, data, false);
                }
              },
              directives: [
                {
                  name: 'grant',
                  value: 'OSP_SYS_MENU_UPDATE'
                }
              ]
            }, '编辑'),
            h('Button', {
              props: {
                type: 'error',
                size: 'small'
              },
              on: {
                click: () => {
                  this.removeData(root, node, data);
                }
              },
              directives: [
                {
                  name: 'grant',
                  value: 'OSP_SYS_MENU_DELETE'
                }
              ]
            }, '删除')
          ])
        ]);
      },
      /**
       * 新增数据
       */
      addData(root, node, data) {
        const parentKey = root.find(el => el === node).parent;
        let parent = null;
        if (parentKey !== undefined) {
          parent = root.find(el => el.nodeKey === parentKey).node;
        }
        this.drawerFlag = 'add';
        this.drawerData = {
          merchantCode: data.row.merchantCode,
          merchantName: MERCHANTS.OSP,
          parentMenuId: parent ? data.row.menuId : '',
          parentMenuName: parent ? data.row.menuName : ''
        };
        this.drawerDisplay = true;
      },
      /**
       * 查看、编辑数据
       */
      editData(root, node, data, onlyRoad = false) {
        const parentKey = root.find(el => el === node).parent;
        let parent = null;
        if (parentKey !== undefined) {
          parent = root.find(el => el.nodeKey === parentKey).node;
        }
        this.drawerFlag = onlyRoad ? 'show' : 'edit';
        this.drawerData = Object.assign({}, data.row, {
          merchantName: MERCHANTS.OSP,
          parentMenuName: parent ? parent.row.menuName : ''
        });
        this.drawerDisplay = true;
      },
      /**
       * 删除数据
       */
      removeData(root, node, data) {
        let name = data.row.menuName;
        if (data.children.length > 0) {
          this.notice(`${name}下还有子菜单，无法删除`);
          return;
        }
        const parentKey = root.find(el => el === node).parent;
        let parent = null;
        if (parentKey !== undefined) {
          parent = root.find(el => el.nodeKey === parentKey).node;
          name = (parent.row.menuName || parent.title) + '-' + name;
        }
        this.$confirm(`确定要删除 [${name}] 吗？`).then(() => {
          this.$axios({
            url: this.$api.system.deleteMenu,
            data: {
              menuId: data.row.menuId
            },
            loading: true
          }).then(data => {
            this.notice({
              type: 'success',
              desc: '删除成功'
            });
            this.getData();
          });
        });
      },
      /**
       * 展开、收缩节点
       */
      toggleExpand(data) {
        this.treeExpand[data.row.menuId] = data.expand;
      },
      /**
       * 全部展开或收起
       */
      expand(type) {
        this.isExpand = type;
        this.treeExpand = {};
        this.changeExpand(this.treeData[0].children);
      },
      /**
       * 遍历树数据更新状态
       */
      changeExpand(list) {
        list.forEach(item => {
          item.expand = this.isExpand;
          if (item.children.length > 0) {
            this.changeExpand(item.children);
          }
        });
      }
    }
  };
</script>